import React from "react";
import Header from "./Header/Header";
import "./Hero.css";
import hero_image from "../Hero/Header/assets/hero_image.png";
import hero_image_back from "../Hero/Header/assets/hero_image_back.png";
// import Heart from "../Hero/Header/assets/heart.png";
import Calories from "../Hero/Header/assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter"
const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile= window.innerWidth<=768 ? true : false;
  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile? "160px" : "180px" }}
            transition={{ ...transition, type: "tween" }}
            whileInView={{ left: "8px" }}
          ></motion.div>
          <span>The best fitness club in rampur</span>
        </div>
        {/* hero heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">SORE </span>
            <span>TODAY</span>
          </div>

          <div>
            <span>STRONG</span> <span className="stroke-text"> TOMORROW</span>
          </div>

          <div>
            <span>
              HEALTH MAY NOT BE EVERYTHING,BUT EVERYTHING IS NOTHING WITHOUT
              HEALTH.
            </span>
          </div>
        </div>

        <div className="figures">
          <div>
            <span><NumberCounter start={0} end={100} delay="3" preFix="+"/> </span>
            <span>EXPERT COACHES</span>
          </div>
          <div>
            <span><NumberCounter start={600} end={987} delay="3" preFix="+"/></span>
            <span>MEMBERS JOINED</span>
          </div>
          <div>
            <span><NumberCounter start={0} end={30} delay="3" preFix="+"/></span>
            <span>FITNESS PROGRAMS</span>
          </div>
        </div>
        {/* hero buttons */}
        <div className="hero-buttons">
          <button className="btn">GET STARTED</button>
          <button className="btn">LEARN MORE</button>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">JOIN NOW</button>
        {/* <motion.div
          whileInView={{ right: "4rem" }}
          initial={{ right: "8rem" }}
          transition={transition}
          className="heart-rate"
        >
          <img src={Heart} alt="" />
          <span>HEART RATE </span>
          <span>116 BPM</span>
        </motion.div> */}
        {/* hero images */}
        <img src={hero_image} alt="" className='hero-image' />
        <img src={hero_image_back} alt="" className="hero-image-back" />
        <motion.div
          whileInView={{ right: "27rem" }}
          initial={{ right: "15rem" }}
          transition={transition}
          className="calories">
          <img src={Calories} alt="" />
          <div>
            <span>CALORIES BURNT </span>
            <span>220KCAL</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
