import React from "react";
import "./Footer.css";
import Github from "../Hero/Header/assets/github.png";
import Instagram from "../Hero/Header/assets/instagram.png";
import LinkedIn from "../Hero/Header/assets/linkedin.png";
import Logo from "../Hero/Header/assets/logo2.png";

const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
      <div className="footer">
       <div className="link-1"><a href="https://github.com/aiti999?query=is%3Aopen&tab=projects" target='_blank'> <img src={Github} alt=""  /></a></div>
       <div className="link-2"><a href="https://www.instagram.com/aitesamkhan/" target='_blank' > <img src={Instagram} alt="" /></a></div>
       <div className="link-3"><a href="https://www.linkedin.com/in/aitesam-khan-019078206/" target='_blank'> <img src={LinkedIn} alt=""/></a></div>

      <div className="logo-f">
        <img src={Logo} alt="" />
        </div>
      </div>
      <div className="blur f-blur-1"></div>
      <div className="blur f-blur-2"></div>
    </div>
  );
};

export default Footer;
