import image1 from "../components/Hero/Header/assets/t-image1.png";
import image2 from "../components/Hero/Header/assets/t-image2.png";
import image3 from "../components/Hero/Header/assets/t-image3.jpg";


export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Oxygen gym and by choosing the right plan and program.I have already achieved my ideal body!",
    name: 'ABDUL SAMAD',
    status : 'MR.RAMPUR(2022)'
  },
  {
    image: image2,
    review: 'At oxygen gym,I guide others to reach their fitness goals, by instructing them with proper training and diet plans.',
    name: 'ZEESHAN KHAN',
    status: 'COACH'
  },
  {
    image : image3,
    review:"Amazing gym,excellent machines and good community.I am glad to be a member of oxygen gym",
    name: 'HAMZA TUFAIL',
    status: "CUSTOMER"
  }
];
