import React from "react";
import "./Plans.css";
import { plansData } from "../../data/plansData.js";
import whiteTick from "../Hero/Header/assets/whiteTick.png"
const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur p-blur-1"></div>
      <div className="blur p-blur-2"></div>
      <div className="programs-header">
        <span className="stroke-text">GET READY TO </span>
        <span>START YOUR JOURNEY </span>
        <span className="stroke-text">WITH US</span>
      </div>
      {/* plans card */}
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>Rs.{plan.price}</span>
            <div className="features">
                {plan.features.map((feature,i)=>(
                    <div className="feature">
                        <img src={whiteTick} alt="" />
                        <span key={i}>{feature}</span>
                    </div>
                ))}</div>
                <button className="btn">JOIN NOW</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
